import firebase from "firebase";
import Header from '../../../components/Header.vue'
import api from '../../../services/api'
import ImageUploader from 'vue-image-upload-resize'
export default {
  
  name: "PainelAdministrativo",
  components: {
    Header,
    ImageUploader
  },

  data() {
    return {

      nomeLogo: null,
      imageLogo: null,

      dadosEmpresa: null,
      logoEmpresa: null,
      nomeEmpresa: null,
      corFundo: null,
      corFonte: null,
      logoPath: null,
      meioAssinaturaCandidato: null,
      
      corFundoNova: null,
      corFonteNova: null,

      telaSelecionada: 'perfilEmpresa',

      // Tela: Usuários

      itemsUsuarios: null,
      fieldsUsuarios: [
        {key: 'nome', label: 'Nome'},
        {key: 'email', label: 'Email'},
        {key: 'editar', label: ''},
        {key: 'remover', label: ''}
      ],

      idUsuarioDel: null,

      usuario: {
        nome: null,
        email: null,
        credenciais: null,
        permissoes: []
      },

      idUsuarioUpdate: null,

      filtro: {
        nome: null,
        email: null,
      },

      listacredenciais: ['Administrador','Supervisor','Analista'],
      listaPermissoes: [],
      permissoes: [],
      credencial: null,

      showModalSavarVisivel: true,
      meiosAssinaturaContratoAdesao: []

      // ---------------------------------------------

    };
  },

  created() {

    this.dadosEmpresa = this.$store.state.empresaSelecionada;
    this.nomeEmpresa = this.dadosEmpresa.nomeEmpresa;
    this.corFundo = this.dadosEmpresa.cor;
    this.corFonte = this.dadosEmpresa.corFonte;
    this.logoPath = this.dadosEmpresa.logoPath; 
    this.meioAssinaturaCandidato = this.dadosEmpresa.meioAssinaturaCandidato

    this.corFundoNova = this.corFundo;
    this.corFonteNova = this.corFonte;

    this.getListaPermissoes()
    this.logarFirebase()
    this.getMeiosAssinaturaContratoAdesao()

  },

  beforeDestroy() {
    this.deslogarFirebase()
    // console.log('Tela Vue destroyed')
  },

  methods: {

    msg(titulo, texto, cor, tempo = 5000) {

      this.toastCount++;
      this.$bvToast.toast(texto, {
        title: titulo,
        variant: cor,
        solid: true,
        autoHideDelay: tempo,
        appendToast: true
      });

    },

    async logarFirebase() {
      let userFirebase = this.$store.state.firebaseAuth.user
      await firebase.auth().signInWithEmailAndPassword(userFirebase.login, userFirebase.pass)
      userFirebase = null
    },

    async deslogarFirebase() {
      await firebase.auth().signOut()
    },

    toPerfilEmpresa() {

      this.telaSelecionada = 'perfilEmpresa'

    },

    toUsuarios() {

      this.telaSelecionada = 'usuarios'
      this.getUsuarios()

    },

    toTesteImagem() {

      this.telaSelecionada = 'testeImagem'

    },

    toUnidades() {

      this.telaSelecionada = 'unidades'

    },

    // Tela: Perfil da empresa

    async saveMudancas() {

      this.showModalSavarVisivel = false
      
      if (this.imageLogo)
      await this.onUpload(this.imageLogo, this.nomeLogo)

      
      const dadosEmpresaUpdate = {
        idEmpresa: this.dadosEmpresa.id,
        nomeEmpresa: this.nomeEmpresa,
        corFundo: this.corFundoNova,
        corFonte: this.corFonteNova,
        logoPath: this.logoPath,
        meioAssinaturaCandidato: this.meioAssinaturaCandidato
      }

      api.put(`web-cliente/painel-admin/empresa`, dadosEmpresaUpdate)
      .then(() => {

        this.hideModalAplicarMudancas()
        // setTimeout(this.$router.push('/login'), 1000)
        this.$router.push('/login')

      })
      .catch(err => {
        // console.log(err.response.status)
        this.showModalSavarVisivel = true

        if (err.response.status == 400) this.msg('ATENÇÃO', err.response.data, 'warning')
        else this.msg('ERRO', err.response.data, 'danger')
      })

    },

    showModalAplicarMudancas() {

      this.$refs['modal-aplicar-mudancas'].show()

    },

    hideModalAplicarMudancas() {

      this.$refs['modal-aplicar-mudancas'].hide()

    },

    selecionarImagem(arquivo) {
      // console.log('arquivo',arquivo.target.id)
      const nomeTratado = this.tratarNomeImg(arquivo.target.files[0].name)

      if (nomeTratado == 'invalido') {
        this.logoEmpresa = null
        this.nomeLogo = null
        this.imageLogo = null
        this.msg("ATENÇÃO", 'Arquivo inválido', "danger");
        return   
      }

      this.nomeLogo = nomeTratado
      this.imageLogo = arquivo.target.files[0]
      // this.onUpload(arquivo.target.files[0], arquivo.target.id, nomeTratado)
    },

    tratarNomeImg(nomeImg) {
      // console.log('nomeImg', nomeImg)
      const nome = nomeImg.split(".");
      const agora = Math.floor(Date.now());
      const ext = nome[1].toLowerCase()

      if (ext == 'png')
          return this.nomeEmpresa + agora + "." + nome[1]
      else
          return 'invalido'

    },

    async onUpload(file, nomeArquivoTratado) {
            
      // console.log("file", file)

      if (!file) return


      try {

          const storageRef = firebase.storage().ref()

          const metadata = {
              // contentType: "image/jpeg,"
              accept: [
                  '.jpg',
                  '.jpeg',
                  '.pdf',
                  '.png'
              ]
          };


          const fileRef = storageRef.child(`logo_empresas/${nomeArquivoTratado}`)
          const uploadTaskSnapshot = await fileRef.put(file, metadata)
          this.logoPath = await uploadTaskSnapshot.ref.getDownloadURL()


      } catch (error) {
          // console.log("ERR ===>", error)
          this.msg('Atenção!', 'Falha ao salvar o logo da empresa.', 'danger')
      }

    },
  

    // --------------------------------------------------------------------------

    // Tela: Usuários

    getListaPermissoes() {
      
      api.get(`web-cliente/painel-admin/usuarios-permissoes`)
      .then(res => {

        this.listaPermissoes = res.data.permissoes
        this.listacredenciais = res.data.permissoesGrupos

      })
      .catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    getMeiosAssinaturaContratoAdesao() {

      api.get('web-cliente/painel-admin/meios-assinatura')
      .then(res => {

        this.meiosAssinaturaContratoAdesao = res.data

      })
      .catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    atualizaListaPermissoes(permissoes) {

      this.usuario.credenciais = permissoes.texto
      this.usuario.permissoes = permissoes.ids
    },

    getUsuarios() {

      let filtroNome = this.filtro.nome
      let filtroEmail = this.filtro.email

      if (!filtroNome) filtroNome = 0
      if (!filtroEmail) filtroEmail = 0
      
      api.get(`web-cliente/painel-admin/usuarios/${this.dadosEmpresa.id}/${filtroNome}/${filtroEmail}`)
      .then(res => {

        this.itemsUsuarios = res.data
        this.filtro.nome = null
        this.filtro.email = null

      })
      .catch(err => this.msg('ERRO', err.response.data, 'danger'))

    },

    showModalEditarUsuario(usuario) {

      this.resetUser()

      const credencialExiste = this.listacredenciais.filter(item => item.text == usuario.credenciais)
      this.credencial = credencialExiste.length > 0 ? credencialExiste[0].value : null
      this.usuario = usuario
      this.$refs['modal-usuario'].show()
    },

    showModalRemoverUsuario(usuario) {

      this.$refs['modal-remover-usuario'].show()
      this.idUsuarioDel = usuario.id
    },

    hideModalRemoverUsuario() {

      this.idUsuarioDel = null
      this.$refs['modal-remover-usuario'].hide()

    },

    showModalCriarUsuario() {

      this.resetUser()

      this.$refs['modal-usuario'].show()

    },

    hideModalCriarUsuario() {

      this.$refs['modal-usuario'].hide()

    },

    resetUser() {

      this.usuario = {
        id: null,
        nome: null,
        email: null,
        credenciais: null,
        permissoes: []
      }

      this.credencial = null
    },


    delUsuario() {

      api.delete(`web-cliente/painel-admin/usuario/${this.dadosEmpresa.id}/${this.idUsuarioDel}`)
      .then(() => {

        this.hideModalRemoverUsuario()
        this.getUsuarios()
        this.msg('SUCESSO', 'Usuário removido.', 'success')

      })
      .catch(err => this.msg('ERRO', err.response.data, 'danger'))
  
    },

    save() {
      this.usuario.id ? this.updateUsuario() : this.saveUsuario()
    },
    
    saveUsuario() {

      this.usuario.idEmpresa = this.dadosEmpresa.id

      api.post(`web-cliente/painel-admin/usuario`, this.usuario)
      .then(() => {

        // Reseta o filtro para o usuário novo usuário aparecer
        this.filtro.nome = null
        this.filtro.email = null

        this.hideModalCriarUsuario()
        this.getUsuarios()

        this.msg('SUCESSO', 'Usuário criado.', 'success')

      })
      .catch(err => {
        if (err.response.status == 400) this.msg('ATENÇÃO', err.response.data, 'warning')
        else this.msg('ERRO', err.response.data, 'danger')
      })

    },

    updateUsuario() {


      api.put(`web-cliente/painel-admin/usuario`, this.usuario)
      .then(() => {

        this.msg('SUCESSO', 'Usuário atualizado.', 'success')
        this.$refs['modal-usuario'].hide()
        this.getUsuarios()

      })
      .catch(err => {
        if (err.response.status == 400) this.msg('ATENÇÃO', err.response.data, 'warning')
        else this.msg('ERRO', err.response.data, 'danger')
      })

    },

    setImage: function (file) {
      this.hasImage = true
      this.image = file
      window.open(file)
    }

    // --------------------------------------------------------------------------

  },

  computed: {

    cssVars() {
      return {
        "--cor-fundo": this.corFundo,
        "--cor-fonte": this.corFonte,
        "--cor-fundo-nova": this.corFundoNova,
        "--cor-fonte-nova": this.corFonteNova
      }
    }

  }
  
}