import firebase from "firebase";
import api from "../../../../services/api";
import Header from '../../../../components/Header.vue'

export default {

    components: {
        Header,
    },

    data() {
        return {

            loading: true,

            dadosEmpresa: null,
            bgColor: "#a1a1a1",

            documentacao: [],
            logo: "",
            listaImgRg: [], // monta html
            listaCarteiraConselho: [], // monta html
            listaNegativaConselho: [], // monta html
            listaDiploma: [], // monta html
            listaVacinacao: [], // monta html
            listaCurriculo: [], // monta html
            listaCriminais: [], // monta html
            listaAssinaturaCarimboCoren: [], // monta html
            listaBlocoVariavel_1500: [], // monta html
            listaBlocoVariavel_1510: [], // monta html
            listaBlocoVariavel_1520: [], // monta html
            listaBlocoVariavel_1530: [], // monta html
            listaBlocoVariavel_1540: [], // monta html


            nomeFoto: [],
            fotoPerfil: null,
            botaoVisivel: true,
            enderecoEdit: true,

            estadoCivil: ["SOLTEIRO(A)", "CASADO(A)", "DIVORCIADO(A)", "SEPARADO(A)", "VIÚVO(A)"],

            racas: [
                "BRANCA",
                "PRETA",
                "PARDA",
                "AMARELA",
                "INDÍGENA",
                "NÃO INFORMADO",
            ],

            sexo: ["MASCULINO", "FEMININO"],
            fumante: ["SIM", "NÃO"],

            labelDadosAdicionais: '',

            escolaridades: [],
            paises: [],
            bancos: [],

            listaConhecimentosTecnicos: [],

            tipoContas: ["CORRENTE", "POUPANÇA"],

            //   dispPlantoes: [],
            listaPlantoes: [
                { text: "Diurno Impar", value: "diaImpar" },
                { text: "Diurno Par", value: "diaPar" },
                { text: "Noturno Impar", value: "noiteImpar" },
                { text: "Noturno Par", value: "noitePar" },
            ],

            //   selProcedimentos: [],
            listaProcedimentos: [
                { text: "Manhã", value: "manha" },
                { text: "Tarde", value: "tarde" },
                { text: "Noite", value: "noite" },
            ],

            tiposDependentes: [],
            dependente: {
                nome: null,
                cpf: null,
                dataNasc: null,
                tipo: null,
            },
            editarDep: false,
            editarIndex: 0,

            fields: [
                { key: "nome", label: "Nome" },
                { key: "cpf", label: "CPF" },
                {
                    key: "dataNasc",
                    label: "Data Nascimento",
                    formatter: (value) => {
                        const dataView = value.split("-");
                        return `${dataView[2]}/${dataView[1]}/${dataView[0]}`;
                    },
                },
                { key: "parentesco", label: "Tipo dependente" }

            ],

            form: {
                nome: "",
                email: "",
                cpf: "",
                estadoCivil: "",
                nacionalidade: "",
                escolaridade: "",
                raca: "",
                dataNasc: "",
                sexo: "",
                restFisica: "",
                fumante: "",
                dadosAdic: "",
                rg: "",
                rgOrgaoEmissor: "",
                rgDataExp: "",
                nomeFuncao: "",
                regProf: "",
                regNum: "",
                regDataVencimento: "",
                pis: "",
                tituloEleitor: "",
                ccm: "",
                cep: "",
                cidade: "",
                uf: "",
                bairro: "",
                endereco: "",
                numEndereco: "",
                complemento: "",
                celular: "",
                celAdic: "",
                fixo: "",
                banco: "",
                agencia: "",
                conta: "",
                contaDig: "",
                tipoConta: "",
                chavePix: "",
                nomeBeneficiario: "",
                cpfBeneficiario: "",
                file1: "",
                dependentes: [],
                urlFotoPerfil: null,
                urlRg: [],
                urlCarteiraConselho: [],
                urlNegativaConselho: [],
                urlDiploma: [],
                urlVacinacao: [],
                urlCurriculo: [],
                urlAntCriminais: [],
                urlAssinaturaCarimboCoren: [],
                urlBlocoVariavel_1500: [],
                urlBlocoVariavel_1510: [],
                urlBlocoVariavel_1520: [],
                urlBlocoVariavel_1530: [],
                urlBlocoVariavel_1540: []
            },

            show: true,
            img1: false,
            img2: false
            
        };
    },

    async created() {
        const dadosEmpresa = this.$store.state.empresaSelecionada
        this.bgColor = dadosEmpresa.cor
        this.logo = dadosEmpresa.logoPath

        this.blocos = dadosEmpresa.blocos
        await this.getBlocosVisiveisDaFuncao()
        await this.getSolicitacao()
        this.loading = false
    },

    methods: {


        msg(titulo, texto, cor, time = 10000) {
            this.toastCount++;
            this.$bvToast.toast(texto, {
                title: titulo,
                variant: cor,
                solid: true,
                autoHideDelay: time,
                appendToast: true,
            });
        },

        blocoVisivel(codBloco) {

            // console.log(codBloco, this.blocos)
            let visivel = false
            this.blocos.forEach(item => {
                // console.log('item', item)
                if (item.cod == codBloco && item.visivel == 'sim')
                visivel = true
            })

            return visivel
        },

        textoTitulo(codBloco) {

            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.titulo
            })

            return texto
        },

        textoBloco(codBloco) {
            let texto = ''
            this.blocos.forEach(item => {
                if (item.cod == codBloco)
                    texto = item.instrucoes
            })

            return texto
        },

        voltar() {
            this.$router.push("/candidatos");
        },

        selecionarImagem(arquivo, listaImg) {
            // console.log('arquivo',arquivo.target.id)
            const nomeTratado = this.tratarNomeImg(arquivo.target.files[0].name)
            const urlTemp = this.getUrlTemp(arquivo.target.files[0])
            listaImg.push({ nome: nomeTratado, url: urlTemp })
            this.onUpload(arquivo.target.files[0], arquivo.target.id, nomeTratado)
        },

        getUrlTemp(image) {
            // console.log(image)
            return URL.createObjectURL(image)
        },

        previwFoto(e) {
            // var tagId = e.target.id
            if (e.target.id == 'foto-perfil') {
                this.form.urlFotoPerfil = URL.createObjectURL(e.target.files[0])
                const nomeTratado = this.tratarNomeImg(e.target.files[0].name)
                this.onUpload(e.target.files[0], e.target.id, nomeTratado)
            }
        },


        async getBlocosVisiveisDaFuncao() {

            const idSolicitacao = localStorage.getItem('idSolicitacao')

            try {
                
                const res = await api.get("web-cliente/candidato/blocos-visiveis-funcao/" + idSolicitacao)

                const blocosVisiveis = res.data
                // console.log('blocosVisiveis', blocosVisiveis)
                this.blocos = blocosVisiveis?.length > 0 
                ? this.blocos.filter(bloco => {
                    return blocosVisiveis.find(blocoVisivel => Number(blocoVisivel) == bloco.cod)
                })
                : this.blocos

                // console.log('this.blocos',this.blocos)
               
            } catch (error) {
                if (!this.dadosEmpresa) this.$router.push("/adesao/erro");  
            }

        },


        async getSolicitacao() {

            const idSolicitacao = localStorage.getItem('idSolicitacao')
            const dadosEmpresa = this.$store.state.empresaSelecionada

            this.escolaridades = dadosEmpresa.escolaridades;
            this.paises = dadosEmpresa.paises;
            this.bancos = dadosEmpresa.bancos;


            try {
                
                const res = await api.get("web-cliente/candidato/documentacao/" + idSolicitacao)

                // console.log('res.data', res.data);

                this.form = res.data
                this.blocosVisiveis = res.data.blocosVisiveis
                this.listaConhecimentosTecnicos = res.data.listaConhecimentosTecnicos

                this.imgRg = this.form.urlRg
                this.listaImgRg = this.form.urlRg.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCarteiraConselho = this.form.urlCarteiraConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaNegativaConselho = this.form.urlNegativaConselho.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaDiploma = this.form.urlDiploma.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaVacinacao = this.form.urlVacinacao.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCurriculo = this.form.urlCurriculo.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaCriminais = this.form.urlAntCriminais.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaAssinaturaCarimboCoren = this.form.urlAssinaturaCarimboCoren.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1500 = this.form.urlBlocoVariavel_1500.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1510 = this.form.urlBlocoVariavel_1510.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1520 = this.form.urlBlocoVariavel_1520.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1530 = this.form.urlBlocoVariavel_1530.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })

                this.listaBlocoVariavel_1540 = this.form.urlBlocoVariavel_1540.map(item => {
                    return {
                        nome: item.split('%')[1].split('?')[0],
                        url: item
                    }
                })




            } catch (error) {
                console.log(error)
                this.msg("ATENÇÃO", 'Erooooooooooooooooooo', "danger");
                // return
                // if (!this.dadosEmpresa) this.$router.push("/adesao/erro");  
            }

        },






        tratarNomeImg(nomeImg) {
            console.log('nomeImg', nomeImg)
            const nome = nomeImg.split(".");
            const agora = Math.floor(Date.now());

            return agora + "." + nome[1];
        },



        async onUpload(file, nomeVariavel) {

            if (!file) return
            // console.log("file", file)

            try {
                const storageRef = firebase.storage().ref()

                this.form.urlImage = null;
                const nomeImg = this.tratarNomeImg(file.name)
                this.nomeFoto.push(nomeImg)

                const metadata = {
                    contentType: "image/jpeg"
                };

                const fileRef = storageRef.child(`fotos_perfil/${nomeImg}`)
                const uploadTaskSnapshot = await fileRef.put(file, metadata)
                    // this.form.urlFotoPerfil = await uploadTaskSnapshot.ref.getDownloadURL()


                if (nomeVariavel == 'foto-perfil') this.form.urlFotoPerfil = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'rg-frente') this.form.urlRgFrente = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'rg-verso') this.form.urlRgVerso = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'carteira-conselho-frente') this.form.urlCarteiraConselhoFrente = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'carteira-conselho-verso') this.form.urlCarteiraConselhoVerso = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'negativa-conselho') this.form.urlNegativaConselho = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'diploma-frente') this.form.urlDiplomaFrente = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'diploma-verso') this.form.urlDiplomaVerso = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'vacinacao-frente') this.form.urlVacinacaoFrente = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'vacinacao-verso') this.form.urlVacinacaoVerso = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'curriculo') this.form.urlCurriculo = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'ant-criminais') this.form.urlAntCriminais = await uploadTaskSnapshot.ref.getDownloadURL()
                else
                if (nomeVariavel == 'ant-criminais') this.form.urlAssinaturaCarimboCoren = await uploadTaskSnapshot.ref.getDownloadURL()


            } catch (error) {
                console.log("ERR ===", error)
                    //alert("Image uploading failed!")
            }

        },




    },

    computed: {
        cssVars() {
            return {
                "--bg-color": this.bgColor,
                "--height": this.height + "px",
            };
        },
    },


};