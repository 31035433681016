import { render, staticRenderFns } from "./Avaliacao.vue?vue&type=template&id=23c13e14&scoped=true"
import script from "./Avaliacao.script.js?vue&type=script&lang=js&external"
export * from "./Avaliacao.script.js?vue&type=script&lang=js&external"
import style0 from "./Avaliacao.css?vue&type=style&index=0&id=23c13e14&prod&scoped=true&lang=css&external"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23c13e14",
  null
  
)

export default component.exports